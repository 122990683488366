import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';
import TopNavAdmin from './TopNav';
import SidebarAdmin from './SideBar';
const OverviewWithBarcodeAdmin = () => {
  const [activeTab, setActiveTab] = useState('Overview');
  
  const breadcrumbs = [
    { label: 'Manufacturing', link: '#' },
    { label: 'Purchase Orders', link: '#' },
    { label: 'Total Orders', link: '#' },
    { label: 'PO1PKM2311001', link: '#' },
    { label: 'Overview', link: '#' },
  ];

  const tabs = [
    'Overview',
    'F&BOM',
    'Requests',
    'Planning',
    'Operations & QC',
    'Packing',
    'Costing'
  ];

  const inventoryData = [
    {
      no: 1,
      rmCode: '2021RF850',
      rmNameCode: 'แป้งตราวัวแดง 850 กก.',
      lot: 'PM123850',
      amount: 850,
      unit: 'g',
      carton: 1,
      kgPerUnit: 0.850,
      status: 'เตรียมแล้ว'
    },
    {
      no: 2,
      rmCode: '2051AF500',
      rmNameCode: 'แป้งตราวัวไง 500 กก.',
      lot: 'PM123850',
      amount: 500,
      unit: 'g',
      carton: 2,
      kgPerUnit: 1.000,
      status: 'เตรียมแล้ว'
    }
  ];
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16 px-4">
      {/* Breadcrumb */}
      <TopNavAdmin isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <SidebarAdmin isOpen={isSidebarOpen} />
      <div className="max-w-7xl mx-auto py-4">
        <div className="flex items-center gap-2 text-sm">
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              <a href={crumb.link} className="text-gray-600 hover:text-orange-500">
                {crumb.label}
              </a>
              {index < breadcrumbs.length - 1 && (
                <ChevronRight size={16} className="text-gray-400" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-sm">
          {/* Tabs & Order Number */}
          <div className="flex justify-between items-center border-b">
            <div className="flex">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-6 py-4 text-sm font-medium border-b-2 transition-colors
                    ${activeTab === tab
                      ? 'border-orange-500 text-orange-500'
                      : 'border-transparent text-gray-500 hover:text-orange-500'}`}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="px-6 py-4 text-sm font-medium">
              PO1PKM2311001 /07 NOV 2023
            </div>
          </div>

          {/* Form Section */}
          <div className="p-6">
            <h2 className="text-lg font-medium mb-4">รายละเอียด</h2>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <div>
                <label className="block text-sm mb-1">
                  รหัสลูกค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="รหัสลูกค้า"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  ชื่อลูกค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ชื่อลูกค้า"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  รหัสสินค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="รหัสสินค้า"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  ชื่อสินค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ชื่อสินค้า"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-6 mb-6">
              <div>
                <label className="block text-sm mb-1">
                  ยี่ห้อ <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ยี่ห้อ"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  กรุ๊ป/สีซอง <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="กรุ๊ป/สีซอง"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  ขนาดบรรจุ <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ขนาดบรรจุ"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-6 mb-6">
              <div>
                <label className="block text-sm mb-1">
                  จำนวน/Pack Size <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="จำนวน/Pack Size"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">จำนวน</label>
                <input
                  type="text"
                  placeholder="จำนวน"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">หน่วย</label>
                <input
                  type="text"
                  placeholder="หน่วย"
                  className="w-full p-2 border rounded-lg"
                />
              </div>
            </div>

            <div className="flex gap-4 mb-6">
              <label className="flex items-center gap-2">
                <input type="checkbox" className="rounded" />
                <span className="text-sm">Include Non Stock Items</span>
              </label>
              <label className="flex items-center gap-2">
                <input type="checkbox" className="rounded" />
                <span className="text-sm">Include Subtracted Items</span>
              </label>
              <label className="flex items-center gap-2">
                <input type="checkbox" className="rounded" />
                <span className="text-sm">Ignore Existing Projected Quantity</span>
              </label>
            </div>

            {/* Inventory Table */}
            <div className="mb-8">
              <h3 className="text-lg font-medium mb-4">สินค้าคงคลัง (Inventory)</h3>
              <table className="w-full">
                <thead className="bg-orange-500 text-white">
                  <tr>
                    <th className="p-3 text-left">No.</th>
                    <th className="p-3 text-left">RM Code</th>
                    <th className="p-3 text-left">RM Name Code</th>
                    <th className="p-3 text-left">Lot.</th>
                    <th className="p-3 text-right">น้ำหนัก</th>
                    <th className="p-3 text-center">หน่วย</th>
                    <th className="p-3 text-center">จำนวน (Carton)</th>
                    <th className="p-3 text-right">น้ำหนัก/หน่วย (kg.)</th>
                    <th className="p-3 text-center">สถานะ</th>
                  </tr>
                </thead>
                <tbody>
                  {inventoryData.map((item) => (
                    <tr key={item.no} className="border-b">
                      <td className="p-3">{item.no}</td>
                      <td className="p-3">{item.rmCode}</td>
                      <td className="p-3">{item.rmNameCode}</td>
                      <td className="p-3">{item.lot}</td>
                      <td className="p-3 text-right">{item.amount}</td>
                      <td className="p-3 text-center">{item.unit}</td>
                      <td className="p-3 text-center">{item.carton}</td>
                      <td className="p-3 text-right">{item.kgPerUnit.toFixed(3)}</td>
                      <td className="p-3 text-center">
                        <span className="text-blue-500">{item.status}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Raw Material Table */}
            {/* ... Similar table structure for Raw Material ... */}

            {/* Packaging Table */}
            {/* ... Similar table structure for Packaging ... */}

            {/* Notes Section */}
            <div className="mb-8">
              <h3 className="text-lg font-medium mb-4">หมายเหตุ</h3>
              <ul className="list-disc pl-5 space-y-2 text-gray-600">
                <li>เนื่องจากลูกค้าเฟรชมีออร์เดอร์ที่เร่งด่วน</li>
                <li>ครั้งที่จ่ายของตามแผนงานที่ได้ไกด์ไลน์การจัดส่ง</li>
                <li>เนื่องจากข้อจำกัด ทางโกดังจึงต้องจัดส่งตามรอบที่ได้กำหนด</li>
                <li>จัดการพิมพ์บาร์โค้ด ร่างบันทึกข้อมูลและเพิ่มการตั้งค่าตามลำดับที่เกี่ยว</li>
                <li>ผ่าน มาตรฐาน GMP.</li>
              </ul>
            </div>

            {/* Barcode */}
            <div className="text-center">
              <img
                src="/api/placeholder/300/80"
                alt="Barcode"
                className="mx-auto mb-2"
              />
              <p className="text-sm text-gray-500">ORD23080100559</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewWithBarcodeAdmin;