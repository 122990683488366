export default function NavigationBar() {
    return (
        <main className="fixed top-0 w-full h-[66px] bg-white grid grid-cols-12 z-50 border shadow-sm">
            <div className="w-full h-full flex items-center col-span-4 md:col-span-6">
                <div className="w-full h-full flex items-center ml-5">
                <img className="w-6 h-6 p-1 cursor-pointer" src="https://cdn-icons-png.flaticon.com/512/7710/7710488.png" alt="hamburger-bar"/>
                <img className="w-12 h-12 p-1 ml-4" src="/LOGO.png" alt="logo" onClick={()=>window.location.replace("/")}/> 
                </div>
            </div>

            <div className="w-full h-full flex items-center col-span-8 md:col-span-6 ">
                <div className="w-full h-full flex items-center  justify-end mr-5 ">
                    <div className="w-80 h-full  ">
                        <div className=" flex justify-center items-center h-full ">
                            <img className="w-6 h-6" src="https://cdn-icons-png.flaticon.com/512/12595/12595889.png" alt="noti-icon"/>
                            <img className="w-8 h-8 rounded-full bg-contain ml-4" src="https://i.insider.com/64c7a2c9048ff200190deaf5?width=800&format=jpeg&auto=webp" alt="profile-icon"/>
                            <div className="flex flex-col justify-center items-center w-max ml-4 h-full ">
                                    <h1 className="text-md font-bold">พร้อมพล พวงประโคน</h1>
                                    <p className="text-sm">พนักงานขาย, ภาคกลาง</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
