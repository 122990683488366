import React from 'react';
import { Plus, Minus, ChevronRight, ShoppingCart, Search } from 'lucide-react';

const ProductCard = ({ product }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm">
      <img 
        src={product.image} 
        alt={product.name}
        className="w-full h-48 object-contain mb-4"
      />
      <h3 className="font-medium text-gray-800 mb-1">{product.name}</h3>
      <p className="text-sm text-gray-500 mb-2">{product.code}</p>
      <div className="flex justify-between items-center text-sm mb-4">
        <span>${product.price}</span>
      </div>
      <div className="flex items-center gap-2 mb-3">
        <button className="w-8 h-8 flex items-center justify-center border border-gray-200 rounded-lg">
          <Plus size={18} className="text-green-600" />
        </button>
        <input
          type="text"
          value="0"
          className="w-12 h-8 text-center border border-gray-200 rounded-lg"
        />
        <button className="w-8 h-8 flex items-center justify-center border border-gray-200 rounded-lg">
          <Minus size={18} className="text-gray-400" />
        </button>
      </div>
      <div className="flex gap-2">
        <button className="flex-1 py-2 bg-green-600 text-white text-sm rounded-lg hover:bg-green-700">
          Place a Bid
        </button>
        <button className="flex-1 py-2 border border-gray-200 text-gray-600 text-sm rounded-lg hover:bg-gray-50">
          View Item
        </button>
      </div>
    </div>
  );
};

const ProductGrid = () => {
  const breadcrumbs = [
    'Manufacturing',
    'Purchase Orders',
    'Total Orders',
    'PO1PKM2311001',
    'Overview'
  ];

  const products = [
    {
      id: 1,
      name: 'แป้งข้าวเหนียวดัดแปลง500gx20ถุง',
      code: '1032PG510',
      price: '2,630',
      image: '/api/placeholder/200/200',
    },
    {
      id: 2,
      name: 'แป้งขัด 20 กก.',
      code: '1061PK020',
      price: '17,035',
      image: '/api/placeholder/200/200',
    },
    // Add more products...
  ];

  return (
    <div className="min-h-screen bg-gray-50 pt-16 px-4">
      {/* Breadcrumb */}
      <div className="max-w-7xl mx-auto py-4">
        <div className="flex items-center gap-2 text-sm">
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              <span className="text-gray-600">{crumb}</span>
              {index < breadcrumbs.length - 1 && (
                <ChevronRight size={16} className="text-gray-400" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Search and Cart */}
      <div className="max-w-7xl mx-auto mb-6">
        <div className="flex items-center justify-between">
          <div className="relative">
            <input
              type="text"
              placeholder="ค้นหา"
              className="w-64 pl-10 pr-4 py-2 border rounded-lg"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
          <button className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 flex items-center gap-2">
            <ShoppingCart size={20} />
            ตะกร้าสินค้า
          </button>
        </div>
      </div>

      {/* Product Grid */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>

      {/* Footer Links */}
      <div className="max-w-7xl mx-auto mt-12 pt-8 border-t">
        <div className="grid grid-cols-3 gap-8">
          <div>
            <h3 className="font-medium mb-4">โรงงานโป้งเหน้ง</h3>
            <p className="text-sm text-gray-600 mb-2">
              เป็นโรงงานที่ได้มาตรฐานจากหลายสาขา GMP,
              HACCP, HALAL จำหน่ายทั้งใน และต่างประเทศ
            </p>
            <p className="text-sm text-gray-600">ติดต่อสอบถามเพิ่มเติมที่ โทร. 03-851-6865</p>
            <p className="text-sm text-gray-600">Email: @pongnamhong.co.th</p>
          </div>
          <div>
            <h3 className="font-medium mb-4">รหัสสินค้า</h3>
            <ul className="text-sm text-gray-600 space-y-2">
              <li>แป้งข้าวเจ้า</li>
              <li>แป้งข้าวเหนียว</li>
              <li>แป้งข้าวโพด</li>
              <li>แป้งมัน</li>
              {/* Add more items */}
            </ul>
          </div>
          <div>
            <h3 className="font-medium mb-4">ชื่อสินค้า</h3>
            <ul className="text-sm text-gray-600 space-y-2">
              <li>ตราเรือ</li>
              <li>Taste of Asia</li>
              <li>Polar Bear</li>
              <li>ตราหมี</li>
              {/* Add more items */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGrid;