import { BsFillTrash3Fill } from "react-icons/bs";
import Footer from "../Nav/Footer";
import CartItem from "./CartItem";

export default function CartPage() {
  const cartItems = [
    { name: "Camisole", size: "Small", price: 8.0, quantity: 1 },
    { name: "Camisole", size: "Small", price: 8.0, quantity: 1 },
    { name: "Camisole", size: "Small", price: 8.0, quantity: 1 },
  
  ];

  return (
    <main className="w-full h-full">
      <div className="w-full h-screen overflow-y-auto">
        <div className="w-11/12 mx-auto grid grid-cols-12 h-full">
          {/* Main Cart Section */}
          <main className="p-2 font-medium col-span-12 md:col-span-9 w-full h-auto mt-32 overflow-hidden">
            <section className="relative w-full h-full rounded border mt-12 md:px-10">
              {/* Header Row */}
              <div className="grid grid-cols-12 w-full h-12 mt-[67px] justify-center items-center px-2.5">
                <input className="col-span-1 flex items-center justify-center border bordre-green-500" type="checkbox" />
                <h1 className="col-span-11 md:col-span-5">PRODUCT</h1>
                <h1 className="hidden md:col-span-2 md:flex justify-end">QUANTITY</h1>
                <h1 className="hidden md:col-span-4 md:text-right md:flex md:justify-end">PRICE</h1>
              </div>
              {/* Cart Navigation */}
              <div className="absolute top-[-2.7rem] border font-medium left-0 w-[64px] h-[42px] flex justify-center items-center rounded">
                Cart
              </div>
              <div className="absolute top-[-2.7rem] border text-white font-medium left-[64px] w-[80px] h-[42px] flex justify-center items-center bg-[#FD7E14] rounded">
                Favorites
              </div>
              <div className="absolute right-3 top-[16px] w-[108px] h-[38px] flex justify-center items-center rounded font-medium cursor-pointer border border-orange-500">
                <BsFillTrash3Fill className="text-orange-500 mr-2.5" />
                <h1 className="text-orange-500">Remove</h1>
              </div>
              {/* Cart Items List */}
              <div className="flex flex-col h-[700px] w-full  overflow-y-auto  ">
                {cartItems.map((item, index) => (
                  <CartItem
                    key={index}
                    name={item.name}
                    size={item.size}
                    price={item.price}
                    quantity={item.quantity}
                  />
                ))}
              </div>
            </section>
          </main>
          {/* Sidebar Summary */}
          <main className="p-1 col-span-12 md:col-span-3 w-full h-auto mt-32">
            <section className="p-2 w-full h-[234px] border rounded shadow-xl">
              <div className="flex justify-between items-center w-full h-12 border-b">
                <h1 className="ml-2.5 font-medium">Subtotal</h1>
                <h1 className="mr-2.5 font-bold">$12.00</h1>
              </div>
              <div className="flex justify-between items-center w-full h-12 mt-[26px]">
                <h1 className="ml-2.5 font-medium">Grand total</h1>
                <h1 className="mr-2.5 font-bold">$12.00</h1>
              </div>
              <div onClick={()=>window.location.replace("/checkout")} className="w-full rounded flex justify-center items-center bg-[#FD7E14] h-[42px] text-white font-bold cursor-pointer mt-[32px]">
                Checkout
              </div>
            </section>
          </main>
        </div>
      </div>
      <Footer />
    </main>
  );
}
