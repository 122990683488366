import { BsFillTrash3Fill } from "react-icons/bs";

function CartItem({ name, size, price, quantity }) {
  return (
    <div className="grid grid-cols-12 content-center w-full h-[158px] border-t border-b">
      <input type="checkbox" className="col-span-1 w-3 ml-[15px] md:ml-[52px]" />
      <img src="/cart.png" alt="product-image" className="w-80 col-span-3 md:w-36 md:col-span-2" />
      <div className="w-full col-span-3">
        <h1>{name}</h1>
        <p className="mt-1 text-sm">{size}</p>
        <p className="font-bold text-sm mt-1.5">${price}</p>
      </div>
      <div className="col-span-5 w-full flex flex-col items-center md:col-span-3">
        <div className="flex justify-around items-center w-[92px] h-[33px] rounded border border-orange-500">
          <span>-</span>
          <h1>{quantity}</h1>
          <span>+</span>
        </div>
        <div className="flex justify-around items-center w-[92px] h-[33px] border border-orange-500 mt-2.5 rounded">
          <BsFillTrash3Fill className="text-orange-500 mr-2.5" />
          <h1 className="text-orange-500 text-[14px]">Remove</h1>
        </div>
      </div>
      <div className="hidden justify-end w-full col-span-3 h-full md:flex">
        <h1 className="font-bold mr-2">${price}</h1>
      </div>
    </div>
  );
}

export default CartItem;
