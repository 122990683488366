import React, { useState } from 'react';
import { Menu, ChevronDown, Home, ShoppingCart, Users, Settings, BarChart2, Package, FileText, Bell, Search } from 'lucide-react';

// Sample data for the orders
const orderData = [
  {
    id: 1,
    poNumber: 'PKM2311001',
    productCode: '1032PC510',
    productName: 'แป้งตราบัวแดงห่อเล็ก500gx20ถุง',
    customer: 'อาร์เอ็ม เทคโนโลยี จำกัด',
    customerCode: '1032PC510',
    complete: 80,
  },
  {
    id: 2,
    poNumber: 'PKM2311002',
    productCode: '1032PK530',
    productName: 'แป้งตราบัวแดงห่อเล็ก 500 ก.x60 ถุง',
    customer: 'อาร์ เอส ฟู้ดส์เทค (ประเทศไทย) จำกัด',
    customerCode: '1032PK530',
    complete: 80,
  },
  // Add more items as needed...
];

// SidebarAdmin Component
const SidebarAdmin = ({ isOpen }) => {
  const menuItems = [
    { icon: <Home size={20} />, label: 'หน้าหลัก' },
    { icon: <ShoppingCart size={20} />, label: 'Orders' },
    { icon: <FileText size={20} />, label: 'เอกสารรายงาน', hasSubmenu: true },
    { icon: <Users size={20} />, label: 'ข้อมูลลูกค้า', hasSubmenu: true },
    { icon: <Users size={20} />, label: 'ข้อมูลพนักงานขาย', hasSubmenu: true },
    { icon: <Package size={20} />, label: 'ข้อมูลสินค้า', hasSubmenu: true },
    { icon: <Package size={20} />, label: 'คลังสินค้า' },
    { icon: <Settings size={20} />, label: 'โปรโมชั่น' },
    { icon: <FileText size={20} />, label: 'ประวัติสั่งซื้อ' },
    { icon: <BarChart2 size={20} />, label: 'สถานะออเดอร์' },
    { icon: <Package size={20} />, label: 'สถานะการจัดส่ง' },
  ];

  return (
    <div 
      className={`fixed left-0 top-16 h-[calc(100vh-4rem)] bg-white shadow-lg transition-transform duration-300 ease-in-out w-64 z-50
        ${isOpen ? 'translate-x-0' : '-translate-x-64'}`}
    >
      <div className="p-4">
        <div className="flex items-center gap-2 text-orange-500">
          <span className="text-sm">Executive</span>
        </div>
      </div>
      <nav className="mt-2">
        {menuItems.map((item, index) => (
          <div key={index}>
            <a
              href="#"
              className="flex items-center px-4 py-2 text-gray-700 hover:bg-orange-50 hover:text-orange-500"
            >
              <span className="mr-3">{item.icon}</span>
              <span className="flex-grow text-sm">{item.label}</span>
              {item.hasSubmenu && (
                <ChevronDown size={16} className="text-gray-400" />
              )}
            </a>
          </div>
        ))}
      </nav>
    </div>
  );
};

// TopNavAdmin Component
const TopNavAdmin = ({ isOpen, toggleSidebar }) => {
  return (
    <div className="fixed top-0 left-0 right-0 h-16 bg-orange-500 shadow-sm flex items-center px-4 z-50">
      <div className="flex items-center gap-4">
        <button
          onClick={toggleSidebar}
          className="p-2 text-white hover:bg-orange-600 rounded-lg"
        >
          <Menu size={24} />
        </button>
        <img src="/logo.png" alt="Logo" className="h-8" />
      </div>
      
      <div className="flex-grow flex justify-end items-center gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="ค้นหา"
            className="pl-10 pr-4 py-2 rounded-lg w-64 focus:outline-none"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        
        <div className="flex items-center gap-3">
          <button className="p-2 text-white hover:bg-orange-600 rounded-full">
            <Bell size={20} />
          </button>
          <div className="flex items-center gap-2 text-white">
            <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center">
              <span className="text-orange-500">E</span>
            </div>
            <span>0234567</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// MainContent Component
const MainContent = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const tabs = [
    'Total Orders',
    'Pending Orders',
    'Approved Orders',
    'Completed Orders',
    'Canceled Orders'
  ];

  const handleSelectItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length === orderData.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(orderData.map(item => item.id));
    }
  };

  return (
    <div className="pt-16 px-4 min-h-screen bg-gray-50">
      <div className="p-4">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              <h1 className="text-xl font-medium">สิทธิการเข้าถึง</h1>
              <div className="relative">
                <input
                  type="text"
                  placeholder="ค้นหา"
                  className="pl-10 pr-4 py-2 border rounded-lg w-64 focus:outline-none focus:border-orange-500"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
              </div>
            </div>
            <div className="flex items-center gap-4">
              <select className="border rounded-lg px-4 py-2">
                <option>ระบุช่วงวัน</option>
              </select>
              <select className="border rounded-lg px-4 py-2">
                <option>ตัวกรอง</option>
              </select>
              <button onClick={()=>window.location.replace("/admin/create/po")} className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600">
                สร้างเลขที่ PO.
              </button>
            </div>
          </div>
          
          <div className="flex gap-8 border-b mb-8">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`px-4 py-2 text-sm ${
                  index === 0
                    ? 'text-orange-500 border-b-2 border-orange-500'
                    : 'text-gray-500 hover:text-orange-500'
                }`}
              >
                {tab}
              </button>
            ))}
          </div>

          {orderData.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-orange-500 text-white">
                  <tr>
                    <th className="p-3 text-left w-12">
                      <input
                        type="checkbox"
                        className="w-4 h-4 rounded"
                        checked={selectedItems.length === orderData.length}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th className="p-3 text-left">No.</th>
                    <th className="p-3 text-left">เลขที่ PO.</th>
                    <th className="p-3 text-left">ชื่อสินค้า</th>
                    <th className="p-3 text-left">ชื่อลูกค้า</th>
                    <th className="p-3 text-center">Complete%</th>
                    <th className="p-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.map((order, index) => (
                    <tr key={order.id} className="border-b hover:bg-gray-50">
                      <td className="p-3">
                        <input
                          type="checkbox"
                          className="w-4 h-4 rounded"
                          checked={selectedItems.includes(order.id)}
                          onChange={() => handleSelectItem(order.id)}
                        />
                      </td>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3 text-gray-500">{order.poNumber}</td>
                      <td className="p-3">
                        <div className="flex items-center gap-3">
                          <img
                            src="/api/placeholder/48/48"
                            alt="Product"
                            className="w-12 h-12 rounded-lg object-cover"
                          />
                          <div>
                            <div className="font-medium">{order.productName}</div>
                            <div className="text-sm text-gray-500">{order.productCode}</div>
                          </div>
                        </div>
                      </td>
                      <td className="p-3">
                        <div>
                          <div className="font-medium">{order.customer}</div>
                          <div className="text-sm text-gray-500">{order.customerCode}</div>
                        </div>
                      </td>
                      <td className="p-3">
                        <div className="w-full flex items-center justify-center gap-2">
                          <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-blue-500 transition-all duration-300 rounded-full"
                              style={{ width: `${order.complete}%` }}
                            />
                          </div>
                          <span className="text-sm text-gray-600">{order.complete}%</span>
                        </div>
                      </td>
                      <td className="p-3 text-center">
                        <button className="px-3 py-1 text-gray-500 bg-gray-100 rounded-lg hover:bg-gray-200">
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              <div className="mt-4 flex items-center justify-between">
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <span>All</span>
                  <span>แสดงข้อมูล 1-12 ของ 100</span>
                </div>
                <div className="flex items-center gap-2">
                  {[1, 2, 3, 4, 5, 6].map((page) => (
                    <button
                      key={page}
                      className={`w-8 h-8 flex items-center justify-center rounded-lg
                        ${page === 2 ? 'bg-orange-500 text-white' : 'text-gray-500 hover:bg-gray-100'}`}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center py-12">
              <FileText size={48} className="text-gray-300 mb-4" />
              <p className="text-gray-500 mb-4">ไม่มีรายการPO.</p>
              <button 
                className="px-4 py-2 text-orange-500 border border-orange-500 rounded-lg hover:bg-orange-50"
                onClick={() => window.location.replace('/admin/create/po')}
              >
                เพิ่มรายการ PO
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Main Dashboard Component
const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <TopNavAdmin isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <SidebarAdmin isOpen={isSidebarOpen} />
      <main className={`transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        <MainContent />
      </main>
      
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
};

export default Dashboard;