import React, { useState } from 'react';
import { CreditCard, PayPal } from 'lucide-react';

const CheckoutPage = () => {
  const [order] = useState({
    items: [
      { name: 'Cardigan', size: 'Small', price: 12.00, quantity: 1 },
      { name: 'Cardigan', size: 'Small', price: 12.00, quantity: 1 }
    ],
    subtotal: 12.00,
    discount: 0.00,
    shipping: 0.00
  });

  const [shippingMethod, setShippingMethod] = useState('free');

  return (
    <div className="min-h-screen bg-gray-50 mt-32">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Column - Forms */}
          <div className="lg:w-2/3">
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Shipping Address</h2>
              <div className="mb-4">
                <select className="w-full p-2 border rounded-md bg-white">
                  <option>Select country</option>
                </select>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="col-span-2">
                  <label className="block text-sm font-medium mb-1">Full Name*</label>
                  <input type="text" className="w-full p-2 border rounded-md" placeholder="Enter your full name" />
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-1">Email Address*</label>
                  <input type="email" className="w-full p-2 border rounded-md" placeholder="Enter your email address" />
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-1">Confirmation Email*</label>
                  <input type="email" className="w-full p-2 border rounded-md" placeholder="Enter your confirmation email" />
                </div>
                
                <div className="col-span-2">
                  <label className="block text-sm font-medium mb-1">Phone Number*</label>
                  <input type="tel" className="w-full p-2 border rounded-md" placeholder="Enter your phone number" />
                </div>
                
                <div className="col-span-2">
                  <label className="block text-sm font-medium mb-1">House Number and Street Name*</label>
                  <input type="text" className="w-full p-2 border rounded-md" placeholder="Enter your house number and street name" />
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-1">City*</label>
                  <input type="text" className="w-full p-2 border rounded-md" placeholder="City" />
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-1">Region</label>
                  <select className="w-full p-2 border rounded-md bg-white">
                    <option>Select region</option>
                  </select>
                </div>
                
                <div className="col-span-2">
                  <label className="block text-sm font-medium mb-1">Postal Code*</label>
                  <input type="text" className="w-full p-2 border rounded-md" placeholder="Enter your postal code" />
                </div>
              </div>
            </div>

            {/* Shipping Method */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Shipping Method</h2>
              <div className="space-y-4">
                <label className="flex items-center p-4 border rounded-md cursor-pointer hover:bg-gray-50">
                  <input
                    type="radio"
                    name="shipping"
                    value="free"
                    checked={shippingMethod === 'free'}
                    onChange={(e) => setShippingMethod(e.target.value)}
                    className="mr-2"
                  />
                  <div className="flex-1">
                    <div className="font-medium">Free Shipping</div>
                    <div className="text-sm text-gray-500">7-30 business days</div>
                  </div>
                  <div className="font-medium">$0</div>
                </label>

                <label className="flex items-center p-4 border rounded-md cursor-pointer hover:bg-gray-50">
                  <input
                    type="radio"
                    name="shipping"
                    value="regular"
                    checked={shippingMethod === 'regular'}
                    onChange={(e) => setShippingMethod(e.target.value)}
                    className="mr-2"
                  />
                  <div className="flex-1">
                    <div className="font-medium">Regular Shipping</div>
                    <div className="text-sm text-gray-500">3-14 business days</div>
                  </div>
                  <div className="font-medium">$5.50</div>
                </label>

                <label className="flex items-center p-4 border rounded-md cursor-pointer hover:bg-gray-50">
                  <input
                    type="radio"
                    name="shipping"
                    value="express"
                    checked={shippingMethod === 'express'}
                    onChange={(e) => setShippingMethod(e.target.value)}
                    className="mr-2"
                  />
                  <div className="flex-1">
                    <div className="font-medium">Express Shipping</div>
                    <div className="text-sm text-gray-500">1-3 business days</div>
                  </div>
                  <div className="font-medium">$23.00</div>
                </label>
              </div>
            </div>

            {/* Payment Method */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Payment Method</h2>
              <div className="space-y-4">
                <div className="border rounded-md p-4">
                  <div className="flex items-center justify-between mb-4">
                    <label className="flex items-center">
                      <input type="radio" name="payment" className="mr-2" defaultChecked />
                      <span className="font-medium">Credit Card</span>
                    </label>
                    <div className="flex gap-2">
                      <img src="/visa.png" alt="Visa" className="h-4 my-auto" />
                      <img src="/mastercard.png" alt="Mastercard" className="h-6" />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="col-span-2">
                      <input type="text" className="w-full p-2 border rounded-md" placeholder="Card Number" />
                    </div>
                    <div className="col-span-2">
                      <input type="text" className="w-full p-2 border rounded-md" placeholder="Name on Card" />
                    </div>
                    <div>
                      <input type="text" className="w-full p-2 border rounded-md" placeholder="Expiration Date (MM/YY)" />
                    </div>
                    <div>
                      <input type="text" className="w-full p-2 border rounded-md" placeholder="CVV" />
                    </div>
                  </div>
                </div>
                
                <div className="border rounded-md p-4">
                  <label className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input type="radio" name="payment" className="mr-2" />
                      <span className="font-medium">PayPal</span>
                    </div>
                    <img src="/paypal.png" alt="PayPal" className="h-6" />
                  </label>
                </div>
              </div>
            </div>

            {/* Billing Address */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4">Billing Address</h2>
              <div className="mb-4">
                <label className="flex items-center">
                  <input type="checkbox" className="mr-2" />
                  <span>Same as my shipping address</span>
                </label>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Add billing address fields here - similar to shipping address */}
              </div>
            </div>
          </div>

          {/* Right Column - Order Summary */}
          <div className="lg:w-1/3">
            <div className="bg-white rounded-lg shadow-sm p-6 sticky top-4">
              <h2 className="text-xl font-semibold mb-4">Your Order</h2>
              <div className="space-y-4 mb-6">
                {order.items.map((item, index) => (
                  <div key={index} className="flex items-center gap-4">
                    <div className="w-16 h-16 bg-gray-100 rounded-md"></div>
                    <div className="flex-1">
                      <h3 className="font-medium">{item.name}</h3>
                      <p className="text-sm text-gray-500">{item.size}</p>
                      <p className="text-sm text-gray-500">{item.quantity} pc</p>
                    </div>
                    <div className="font-medium">${item.price.toFixed(2)}</div>
                  </div>
                ))}
              </div>

              <div className="mb-4">
                <div className="flex items-center">
                  <input
                    type="text"
                    className="flex-1 p-2 border rounded-l-md"
                    placeholder="Add discount code"
                  />
                  <button className="px-4 py-2 bg-orange-500 text-white rounded-r-md hover:bg-orange-600">
                    Apply
                  </button>
                </div>
              </div>

              <div className="space-y-2 border-t pt-4 mb-6">
                <div className="flex justify-between">
                  <span>Subtotal</span>
                  <span>${order.subtotal.toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span>Discount</span>
                  <span>${order.discount.toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span>Shipment Cost</span>
                  <span>${order.shipping.toFixed(2)}</span>
                </div>
                <div className="flex justify-between font-semibold text-lg pt-2 border-t">
                  <span>Grand Total</span>
                  <span>${(order.subtotal - order.discount + order.shipping).toFixed(2)}</span>
                </div>
              </div>

              <button className="w-full py-3 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors">
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;