import { FaFacebook } from "react-icons/fa";
import { FaSnapchat } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className="w-full flex flex-col z-50 bg-gray-100 py-4  ">
        <div className="flex flex-col justify-between w-full px-8 mx-auto  h-[200   px] mb-2.5 border-b-2  md:flex-row md:h-[150px]">
            <div className="w-max h-full">
                <h1 className="font-extrabold">Outfit</h1>

                <p className="mt-3">Discover Your Distinctive Look:</p>
                <p>Fashioned with Precision, Worn with Confidence</p>
            </div>

            <div className="w-full h-full  flex flex-col  justify-end  md:w-[460px] ">
                <div className="flex flex-col  w-full h-auto md:flex-row md:justify-around md:items-center ">
                    <h1 className="font-medium text-sm mt-6 md:mt-0">Home</h1>
                    <h1 className="font-medium text-sm mt-4 md:mt-0">Products</h1>
                    <h1 className="font-medium text-sm mt-4 md:mt-0">Sale</h1>
                    <h1 className="font-medium text-sm mt-4 md:mt-0">Cart</h1>
                    <h1 className="font-medium text-sm mt-4 mb-6 md:mt-0 md:mb-0">Checkout</h1>

                </div>
                <div className="flex justify-start text-3xl w-full h-auto mt-[25px] mb-6 md:justify-end md:mb-6">
                    <FaFacebook className="mr-[29px]"/>
                    <FaSquareInstagram className="mr-[29px]"/>
                    <FaSnapchat className="mr-[29px]"/>
                </div>
            </div>
        </div>
      <div className="flex flex-col w-full h-full md:items-center md:flex-row ">
        <div className="order-2 container w-11/12 mx-auto text-[12px] md:indent-5 mt-2.5 md:mt-0 ">Privacy Policy</div>
        <div className="order-1 container font-bold mx-auto w-11/12  text-[12px]  md:order-2 md:text-center ">
          &copy; 2024 Pornkamon Rice Flour Mills Co., Ltd. All rights reserved.
        </div>
        <div className="order-3 container w-11/12  mx-auto text-[12px] md:text-right md:mr-5 mt-2.5 md:mt-0">Terms & Conditions</div>
      </div>
    </footer>
  );
}
