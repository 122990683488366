import { Menu, ChevronDown, Home, ShoppingCart, Users, Settings, BarChart2, Package, FileText, Bell, Search } from 'lucide-react';

const SidebarAdmin = ({ isOpen }) => {
    const menuItems = [
      { icon: <Home size={20} />, label: 'หน้าหลัก' },
      { icon: <ShoppingCart size={20} />, label: 'Orders' },
      { icon: <FileText size={20} />, label: 'เอกสารรายงาน', hasSubmenu: true },
      { icon: <Users size={20} />, label: 'ข้อมูลลูกค้า', hasSubmenu: true },
      { icon: <Users size={20} />, label: 'ข้อมูลพนักงานขาย', hasSubmenu: true },
      { icon: <Package size={20} />, label: 'ข้อมูลสินค้า', hasSubmenu: true },
      { icon: <Package size={20} />, label: 'คลังสินค้า' },
      { icon: <Settings size={20} />, label: 'โปรโมชั่น' },
      { icon: <FileText size={20} />, label: 'ประวัติสั่งซื้อ' },
      { icon: <BarChart2 size={20} />, label: 'สถานะออเดอร์' },
      { icon: <Package size={20} />, label: 'สถานะการจัดส่ง' },
    ];
  
    return (
      <div 
        className={`fixed left-0 top-16 h-[calc(100vh-4rem)] bg-white shadow-lg transition-transform duration-300 ease-in-out w-64 z-50
          ${isOpen ? 'translate-x-0' : '-translate-x-64'}`}
      >
        <div className="p-4">
          <div className="flex items-center gap-2 text-orange-500">
            <span className="text-sm">Executive</span>
          </div>
        </div>
        <nav className="mt-2">
          {menuItems.map((item, index) => (
            <div key={index}>
              <a
                href="#"
                className="flex items-center px-4 py-2 text-gray-700 hover:bg-orange-50 hover:text-orange-500"
              >
                <span className="mr-3">{item.icon}</span>
                <span className="flex-grow text-sm">{item.label}</span>
                {item.hasSubmenu && (
                  <ChevronDown size={16} className="text-gray-400" />
                )}
              </a>
            </div>
          ))}
        </nav>
      </div>
    );
  };

export default  SidebarAdmin