import React, { useState } from 'react';
import { Calendar, Clock } from 'lucide-react';
import TopNavAdmin from './TopNav';
import SidebarAdmin from './SideBar';
const NewPurchaseOrder = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
 
  const [formData, setFormData] = useState({
    orderNumber: 'PKM2305007',
    salesInfo: {
      name: '',
      codeFront: '',
      codeBack: '',
      phoneNumber: '',
      companyType: '',
      orderDate: '',
      orderTime: ''
    },
    recipientInfo: {
      name: '',
      phoneNumber: '',
      taxId: ''
    },
    addressInfo: {
      address: '',
      province: '',
      district: '',
      subDistrict: '',
      postalCode: ''
    },
    productInfo: [],
    verifierInfo: {
      name: '',
      notes: ''
    }
  });

  return (
    
    <div className="min-h-screen bg-gray-50 pt-16 px-4">
         <TopNavAdmin isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
         <SidebarAdmin isOpen={isSidebarOpen} />
      <div className="max-w-6xl mx-auto py-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-xl font-medium">New Purchase Order</h1>
            <div className="flex gap-4">
              <button className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50">
                ยกเลิก
              </button>
              <button className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600">
                บันทึก
              </button>
            </div>
          </div>

          {/* Order Number */}
          <div className="text-lg font-medium mb-8">{formData.orderNumber}</div>

          {/* Sales Person Information */}
          <div className="mb-8">
            <h2 className="text-base font-medium mb-4">ข้อมูลพนักงานขนส่ง</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm mb-1">
                  ชื่อพนักงานขนส่ง <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ระบุชื่อพนักงานขนส่ง"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  ทะเบียนรถ (ด้านหน้า) <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="00-0000"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  ทะเบียนรถ (ด้านหลัง) <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="00-0000"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  เบอร์ติดต่อ <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="000-000-0000"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  ประเภทรถขนส่ง <span className="text-red-500">*</span>
                </label>
                <select className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500">
                  <option value="">เลือกประเภทรถขนส่ง</option>
                </select>
              </div>
              <div>
                <label className="block text-sm mb-1">วันที่รับส่ง</label>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="เลือกวันที่รับสินค้า"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                  />
                  <Calendar className="absolute right-3 top-2.5 text-gray-400" size={20} />
                </div>
              </div>
              <div>
                <label className="block text-sm mb-1">เวลา</label>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="เลือกเวลา"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                  />
                  <Clock className="absolute right-3 top-2.5 text-gray-400" size={20} />
                </div>
              </div>
            </div>
          </div>

          {/* Recipient Information */}
          <div className="mb-8">
            <h2 className="text-base font-medium mb-4">ข้อมูลผู้รับสินค้า</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm mb-1">
                  ชื่อผู้รับสินค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ระบุชื่อพนักงาน"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  เบอร์ติดต่อ <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="000-000-0000"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  เลขที่กรมพัฒน์ <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="00-000-000"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
            </div>
          </div>

          {/* Address Information */}
          <div className="mb-8">
            <h2 className="text-base font-medium mb-4">ข้อมูลที่อยู่</h2>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm mb-1">
                  ที่อยู่จัดส่ง <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ระบุที่อยู่จัดส่ง"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm mb-1">
                    จังหวัด <span className="text-red-500">*</span>
                  </label>
                  <select className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500">
                    <option value="">เลือกจังหวัด</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm mb-1">
                    ประเภทสินค้า <span className="text-red-500">*</span>
                  </label>
                  <select className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500">
                    <option value="">เลือกประเภท/อำเภอ</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm mb-1">
                    แขวง/ตำบล <span className="text-red-500">*</span>
                  </label>
                  <select className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500">
                    <option value="">เลือกแขวง/ตำบล</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Product Information */}
          <div className="mb-8">
            <h2 className="text-base font-medium mb-4">ข้อมูลสินค้า</h2>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-4">
              <div>
                <label className="block text-sm mb-1">
                  ประเภทสินค้า <span className="text-red-500">*</span>
                </label>
                <select className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500">
                  <option value="">ประเภทสินค้า</option>
                </select>
              </div>
              <div>
                <label className="block text-sm mb-1">
                  รหัสสินค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ระบุรหัสสินค้า"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  ชื่อสินค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ระบุชื่อสินค้า"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  จำนวนสินค้า <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  placeholder="0"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  หน่วยสินค้า <span className="text-red-500">*</span>
                </label>
                <select className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500">
                  <option value="">เลือกหน่วย</option>
                </select>
              </div>
            </div>
            <button className="w-full p-2 text-center border border-dashed border-gray-300 rounded-lg text-gray-500 hover:bg-gray-50">
              + เพิ่มสินค้า
            </button>
          </div>

          {/* Verifier Information */}
          <div>
            <h2 className="text-base font-medium mb-4">ข้อมูลผู้ตรวจสอบ</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm mb-1">
                  ผู้ตรวจสอบข้อมูลขนส่ง <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  placeholder="ลงชื่อผู้ตรวจสอบข้อมูล"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">หมายเหตุ</label>
                <input
                  type="text"
                  placeholder="ระบุหมายเหตุ (ถ้ามี)"
                  className="w-full p-2 border rounded-lg focus:outline-none focus:border-orange-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPurchaseOrder;