import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Footer from "./Nav/Footer";
import { CiHeart } from "react-icons/ci";
import axios from "axios";

export default function Products() {
  const [CategoryOpen, setCategoryOpen] = useState(false);
  const [FlourOpen, setFlourOpen] = useState(false);
  const [RiceOpen, setRiceOpen] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); // For storing selected checkboxes
  const sizes = ["500 g", "1 Kg", "25 Kg", "50 Kg"];
  console.log(allProducts)
  // Fetch products data from API
  function getAllProducts() {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/product/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const products = res.data.data;
        setAllProducts(products);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // Fetch products based on selected filters
  function getProductFromFilter() {
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API}/api/v1/product/filter`,
        {
          categories: selectedItems,
          productType: "Power",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAllProducts(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    // Fetch all products if no filter is selected
    if (selectedItems.length === 0) {
      getAllProducts();
    } else {
      getProductFromFilter(); // Fetch filtered products when any category is selected
    }
  }, [selectedItems]);

  // Handle checkbox change
  const handleCheckboxChange = (item) => {
    setSelectedItems((prevItems) => {
      if (prevItems.includes(item)) {
        return prevItems.filter((i) => i !== item); // Remove item if already selected
      } else {
        return [...prevItems, item]; // Add item if not already selected
      }
    });
  };

  const toggleDropdown = () => setCategoryOpen(!CategoryOpen);

  return (
    <main className="w-full h-auto border overflow-hidden">
      <div className="mx-auto grid grid-cols-12 px-2.5 w-full h-screen mt-32 md:h-[730px]">
        <div className="col-span-12 w-full h-full overflow-y-auto md:col-span-3 lg:col-span-2 border p-2">
          <div
            className="flex justify-between items-center indent-4 w-full min-h-[56px] bg-[#FFE5D0] rounded cursor-pointer"
            onClick={toggleDropdown}
          >
            <h1 className="font-medium">Category</h1>
            <motion.img
              src="https://cdn-icons-png.flaticon.com/512/32/32195.png"
              alt="drop_down"
              className="w-4 h-4 mr-2.5"
              animate={{ rotate: CategoryOpen ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            />
          </div>

          {/* Category - Flour Section */}
          <main className={CategoryOpen ? "w-11/12 mx-auto mt-2.5" : "hidden"}>
            <div
              className="flex justify-between items-center indent-4 w-full min-h-[56px] rounded cursor-pointer"
              onClick={() => setFlourOpen(!FlourOpen)}
            >
              <h1 className="font-medium">Flour</h1>
              <motion.img
                src="https://cdn-icons-png.flaticon.com/512/32/32195.png"
                alt="drop_down"
                className="w-4 h-4 mr-2.5"
                animate={{ rotate: FlourOpen ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              />
            </div>
          </main>

          {/* Flour checkbox section */}
          <section
            className={FlourOpen && CategoryOpen ? "flex flex-col items-center w-10/12 mx-auto" : "hidden"}
          >
            {["Flour", "Rice", "Sago Seeds", "Organic Rice", "Organic Flour"].map(
              (item, index) => (
                <div className="w-full flex mt-2.5" key={index}>
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <h1 className="ml-1.5 font-medium">{item}</h1>
                </div>
              )
            )}
          </section>

          {/* Category - Rice Section */}
          <main className={CategoryOpen ? "w-11/12 mx-auto mt-2.5" : "hidden"}>
            <div
              className="flex justify-between items-center indent-4 w-full min-h-[56px] rounded cursor-pointer"
              onClick={() => setRiceOpen(!RiceOpen)}
            >
              <h1 className="font-medium">Rice</h1>
              <motion.img
                src="https://cdn-icons-png.flaticon.com/512/32/32195.png"
                alt="drop_down"
                className="w-4 h-4 mr-2.5"
                animate={{ rotate: RiceOpen ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              />
            </div>
          </main>

          {/* Rice checkbox section */}
          <section
            className={RiceOpen && CategoryOpen ? "flex flex-col items-center w-10/12 mx-auto" : "hidden"}
          >
            {["Flour", "Rice", "Sago Seeds", "Organic Rice", "Organic Flour"].map(
              (item, index) => (
                <div className="w-full flex mt-2.5" key={index}>
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <h1 className="ml-1.5 font-medium">{item}</h1>
                </div>
              )
            )}
          </section>
        </div>

        {/* Products display section */}
        <div className="overflow-y-auto col-span-12 flex flex-col md:col-span-9 lg:col-span-10">
          <div className="w-max h-auto">
            <h1 className="font-bold">Showing {allProducts.length} results for selected categories</h1>
            <div className="flex flex-col ml-2.5 justify-center items-center w-full h-auto my-6 md:flex-row">
              <h1>Applied Filters</h1>
              {selectedItems.map((item, index) => (
                <div
                  key={index}
                  className="border shadow-xl rounded-full w-[130px] h-[37px] ml-2.5 flex justify-center items-center"
                >
                  {item} x
                </div>
              ))}
            </div>
          </div>
          <div className="h-max overflow-y-auto p-1">
            <section className="mb-12 mt-2.5">
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {allProducts.map((item, index) => (
                  <div
                    key={index}
                    className="relative rounded-lg p-1 flex flex-col items-center border md:p-4"
                  >
                    <img
                      src={item?.product_images[0]}
                      alt={item.name}
                      className="w-full h-full object-contain mb-2 rounded-lg"
                    />
                    <div className="w-full h-full max-h-36">
                      <h3 className="font-semibold">{item.name}</h3>
                      <p className="font-bold">${item?.price}</p>
                    </div>
                    <div className="flex flex-wrap w-full h-full md:flex-row">
                      {sizes.map((size, idx) => (
                        <div
                          key={idx}
                          className="flex justify-center items-center rounded my-2.5 w-[44px] h-9 border border-[#FD7E14] mr-2.5 md:w-[58px]"
                        >
                          <p className="text-[10px] md:text-lg text-orange-400">{size}</p>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-between w-full mt-2">
                      <button className="bg-orange-500 text-white px-2.5 py-1 rounded font-medium">
                        Add to Cart
                      </button>
                    </div>
                    <div className="absolute bottom-1.5 right-2.5 flex justify-center items-center w-8 h-8 rounded-full bg-[#FD7E14] md:bottom-5 md:w-10 md:h-10">
                      <CiHeart className="text-white text-2xl cursor-pointer" />
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
