import React from 'react';
import { ChevronRight, Bookmark, Home, BookOpen, Bell, User } from 'lucide-react';

const VideoCard = ({ title, subject, duration, views, rating }) => (
  <div className="relative group">
    <div className="relative bg-gray-800 rounded-lg overflow-hidden">
      <div className="aspect-video bg-gray-700"></div>
      <button className="absolute top-2 right-2 p-1 rounded-full bg-black/50 text-white">
        <Bookmark size={20} />
      </button>
      <div className="p-3 space-y-2">
        <h3 className="text-white font-medium line-clamp-2">{title}</h3>
        <div className="flex items-center text-gray-400 text-sm">
          <span>{subject}</span>
          <span className="mx-2">•</span>
          <span>{views}K views</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-1">
            {[...Array(5)].map((_, i) => (
              <span key={i} className={`text-sm ${i < rating ? 'text-yellow-400' : 'text-gray-600'}`}>
                ★
              </span>
            ))}
            <span className="ml-1 text-gray-400 text-sm">{rating}</span>
          </div>
          <span className="text-gray-400 text-sm">{duration}</span>
        </div>
      </div>
    </div>
  </div>
);

const VideoSection = ({ title, videos }) => (
  <div className="mb-8">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-white text-xl font-medium">{title}</h2>
      <button className="flex items-center text-yellow-400 hover:text-yellow-300">
        ดูทั้งหมด
        <ChevronRight size={20} />
      </button>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4">
      {videos.map((video, idx) => (
        <VideoCard key={idx} {...video} />
      ))}
    </div>
  </div>
);

const TopNav = () => (
  <div className="flex justify-between items-center p-4 bg-gray-900">
    <div className="flex space-x-2">
      <button className="px-4 py-2 bg-yellow-400 rounded-full text-black font-medium">
        ทั้งหมด
      </button>
      <button className="px-4 py-2 bg-gray-800 rounded-full text-white hover:bg-gray-700">
        ระดับชั้น
      </button>
      <button className="px-4 py-2 bg-gray-800 rounded-full text-white hover:bg-gray-700">
        วิชา
      </button>
      <button className="px-4 py-2 bg-gray-800 rounded-full text-white hover:bg-gray-700">
        ตัวเลือก
      </button>
    </div>
  </div>
);

const BottomNav = () => (
  <div className="fixed bottom-0 w-full bg-gray-900 border-t border-gray-800">
    <div className="flex justify-around py-2">
      <button className="flex flex-col items-center text-gray-400 hover:text-yellow-400">
        <Home size={24} />
        <span className="text-xs mt-1">หน้าหลัก</span>
      </button>
      <button className="flex flex-col items-center text-gray-400 hover:text-yellow-400">
        <BookOpen size={24} />
        <span className="text-xs mt-1">ห้องเรียน</span>
      </button>
      <button className="flex flex-col items-center text-gray-400 hover:text-yellow-400">
        <Bell size={24} />
        <span className="text-xs mt-1">ข่าวสาร</span>
      </button>
      <button className="flex flex-col items-center text-gray-400 hover:text-yellow-400">
        <User size={24} />
        <span className="text-xs mt-1">โปรไฟล์</span>
      </button>
    </div>
  </div>
);

const VideoApp = () => {
  const sampleVideos = [
    {
      title: "พื้นฐานคณิตศาสตร์ ม.ต้น - ระบบสมการเชิงเส้น",
      subject: "คณิตศาสตร์",
      duration: "04:52:25",
      views: "2.0",
      rating: 4.3
    },
    {
      title: "ความน่าจะเป็น",
      subject: "คณิตศาสตร์",
      duration: "02:34:48",
      views: "2.0",
      rating: 4.3
    },
    {
      title: "เส้นขนาน",
      subject: "คณิตศาสตร์",
      duration: "02:03:12",
      views: "2.0",
      rating: 4.3
    },
    {
      title: "ความเท่ากันทุกประการ",
      subject: "คณิตศาสตร์",
      duration: "02:53:05",
      views: "2.0",
      rating: 4.3
    },
    {
      title: "การเขียนและเศษส่วน",
      subject: "คณิตศาสตร์",
      duration: "04:28:24",
      views: "2.0",
      rating: 4.3
    },
    {
      title: "ปฏิสัมพันธ์ในระบบสุริยะ",
      subject: "วิทยาศาสตร์",
      duration: "02:53:00",
      views: "2.0",
      rating: 4.3
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900">
      <TopNav />
      <div className="container mx-auto px-4 pb-20">
        <VideoSection title="บทเรียนมาใหม่" videos={sampleVideos} />
        <VideoSection title="บทเรียนแนะนำ" videos={sampleVideos} />
        <VideoSection title="ม.ปลาย : พื้นฐาน คณิต" videos={sampleVideos} />
      </div>
      <BottomNav />
    </div>
  );
};

export default VideoApp;