import { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";

export default function NavFunctionBar() {
  const saleCartItems = ["Product", "Sale", "Cart"];
  const [toggleMenu, setToggleMenu] = useState(false);

  // Animation variants for the menu
  const menuVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <main className="flex border justify-center items-center w-full h-[56px] fixed top-[66px] mx-auto z-50">
      <div className="grid grid-cols-12 w-full h-full bg-white">
        <div className="col-span-8 flex ml-2.5 items-center w-full h-full md:ml-16 md:col-span-7">
          <h1 className="font-medium">
            {`${toggleMenu ? "Menu" : "Pornkamon Rice Flour Mills Co., Ltd"}`}
          </h1>
        </div>
        <div className="hidden col-span-4 ml-16 items-center w-full h-full md:col-span-5 md:flex">
          <div className="ml-4 cursor-pointer" onClick={()=>window.location.replace('/products')}>
            <p className="text-sm font-medium">Product</p>
          </div>

          <div className="ml-4 cursor-pointer" onClick={()=>window.location.replace('/sale')}>
            <p className="text-sm font-medium">Sale</p>
          </div>

          <div className="flex justify-center items-center w-max ml-4 cursor-pointer" onClick={()=>window.location.replace('/cart')}>
            <p className="text-sm font-medium">Cart</p>
            <div className="ml-2 w-8 h-10 bg-[#FD7E14] rounded-full flex justify-center items-center text-white">
              0
            </div>
          </div>

          <input
            className="w-[205px] h-[36px] my-auto border rounded indent-1 ml-4 text-sm font-bold"
            placeholder="Search"
          />
          <div className="bg-[#FD7E14] w-24 rounded h-[36px] flex justify-center items-center text-white ml-2.5">
            Search
          </div>
        </div>
        <div
          className="col-span-4 flex justify-center items-center text-2xl w-full h-full md:hidden"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <RxHamburgerMenu
            className={`border p-1 rounded text-4xl ${
              toggleMenu ? "hidden" : ""
            }`}
          />
          <IoMdClose
            className={`border p-1 rounded text-4xl ${
              toggleMenu ? "" : "hidden"
            }`}
          />
        </div>

        <AnimatePresence>
          {toggleMenu && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={menuVariants}
              transition={{ duration: 0.3 }}
              className="top-[120px] overflow-y-auto flex flex-col col-span-12 w-full h-[270px] bg-[#FFE5D0] fixed md:hidden"
            >
              <div className="overflow-y-auto w-full h-[210px]">
                <div className="w-10/12 flex items-center font-medium mx-auto h-14" onClick={()=>window.location.replace('/')}>
                  Home
                </div>
                <div className="w-10/12 flex items-center font-medium mx-auto h-14" onClick={()=>window.location.replace('/products')}>
                  Products
                </div>
                <div className="w-10/12 flex items-center font-medium mx-auto h-14" onClick={()=>window.location.replace('/sale')}>
                  Sale
                </div>
                <div className="w-10/12 flex items-center font-medium mx-auto h-14" onClick={()=>window.location.replace('/cart')}>
                  Cart
                </div>
              </div>

              <div className="flex justify-center items-center w-10/12 mx-auto h-[36px] mt-2.5">
                <input
                  className="w-full h-[36px] my-auto rounded indent-1 text-sm font-bold"
                  placeholder="Search"
                />
                <div className="bg-[#FD7E14] w-24 rounded h-[36px] flex justify-center items-center text-white ml-2.5">
                  Search
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </main>
  );
}
