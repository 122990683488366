import { Menu, ChevronDown, Home, ShoppingCart, Users, Settings, BarChart2, Package, FileText, Bell, Search } from 'lucide-react';

const TopNavAdmin = ({ isOpen, toggleSidebar }) => {
    return (
      <div className="fixed top-0 left-0 right-0 h-16 bg-orange-500 shadow-sm flex items-center px-4 z-50">
        <div className="flex items-center gap-4">
          <button
            onClick={toggleSidebar}
            className="p-2 text-white hover:bg-orange-600 rounded-lg"
          >
            <Menu size={24} />
          </button>
          <img src="/logo.png" alt="Logo" className="h-8" />
        </div>
        
        <div className="flex-grow flex justify-end items-center gap-4">
          <div className="relative">
            <input
              type="text"
              placeholder="ค้นหา"
              className="pl-10 pr-4 py-2 rounded-lg w-64 focus:outline-none"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
          
          <div className="flex items-center gap-3">
            <button className="p-2 text-white hover:bg-orange-600 rounded-full">
              <Bell size={20} />
            </button>
            <div className="flex items-center gap-2 text-white">
              <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center">
                <span className="text-orange-500">E</span>
              </div>
              <span>0234567</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default TopNavAdmin