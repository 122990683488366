import React, { useState } from 'react';
import { ChevronRight, Minus, Plus } from 'lucide-react';
import TopNavAdmin from './TopNav';
import SidebarAdmin from './SideBar';
const ProductDetailAdmin = () => {
  const [quantity, setQuantity] = useState(0);
  
  const breadcrumbs = [
    { label: 'Manufacturing', link: '#' },
    { label: 'Purchase Orders', link: '#' },
    { label: 'Total Orders', link: '#' },
    { label: 'PO1PKM2311001', link: '#' },
    { label: 'Overview', link: '#' },
  ];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const relatedProducts = [
    {
      id: 1,
      name: 'แป้งข้าวเจ้า',
      code: '1061PKG20',
      image: '/api/placeholder/200/200',
    },
    {
      id: 2,
      name: 'แป้งข้าวเหนียว 20กgx1กก.',
      code: '1061PKG20',
      image: '/api/placeholder/200/200',
    },
    {
      id: 3,
      name: 'แป้งข้าวเหนียว 20กgx1กก.',
      code: '1032PKS10',
      image: '/api/placeholder/200/200',
    },
    {
      id: 4,
      name: 'แป้งข้าวเหนียวดัดแปลง',
      code: '1032PKS10',
      image: '/api/placeholder/200/200',
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 pt-16 px-4">
      {/* Breadcrumb */}
      <TopNavAdmin isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <SidebarAdmin isOpen={isSidebarOpen} />
      <div className="max-w-7xl mx-auto py-4">
        <div className="flex items-center gap-2 text-sm">
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              <a href={crumb.link} className="text-gray-600 hover:text-orange-500">
                {crumb.label}
              </a>
              {index < breadcrumbs.length - 1 && (
                <ChevronRight size={16} className="text-gray-400" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column - Product Image */}
            <div>
              <h2 className="text-xl font-medium mb-4">รูปภาพสินค้า</h2>
              <div className="border rounded-lg p-4">
                <img
                  src="/api/placeholder/400/400"
                  alt="Product"
                  className="w-full object-contain"
                />
              </div>
            </div>

            {/* Right Column - Product Details */}
            <div>
              <h2 className="text-xl font-medium mb-4">รายละเอียดสินค้า</h2>
              <div className="space-y-4">
                {/* Product Code */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm mb-1">
                      รหัสสินค้า <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="รหัสสินค้า"
                      className="w-full p-2 border rounded-lg"
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm mb-1">
                      ชื่อสินค้า <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="ชื่อสินค้า"
                      className="w-full p-2 border rounded-lg"
                      readOnly
                    />
                  </div>
                </div>

                {/* Product Type */}
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm mb-1">
                      ยี่ห้อ <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="ยี่ห้อ"
                      className="w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm mb-1">
                      กรุ๊ป/สีซอง <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="กรุ๊ป/สีซอง"
                      className="w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm mb-1">
                      ขนาดบรรจุ <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="ขนาดบรรจุ"
                      className="w-full p-2 border rounded-lg"
                    />
                  </div>
                </div>

                {/* Pack Size */}
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm mb-1">
                      จำนวน/Pack Size <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="จำนวน"
                      className="w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm mb-1">หน่วย</label>
                    <input
                      type="text"
                      placeholder="หน่วย"
                      className="w-full p-2 border rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm mb-1">ราคา/หน่วย</label>
                    <input
                      type="text"
                      placeholder="ราคา/หน่วย"
                      className="w-full p-2 border rounded-lg"
                    />
                  </div>
                </div>

                {/* Quantity */}
                <div className="flex items-center gap-4">
                  <button
                    onClick={() => setQuantity(Math.max(0, quantity - 1))}
                    className="p-2 border rounded-lg hover:bg-gray-50"
                  >
                    <Minus size={20} />
                  </button>
                  <input
                    type="text"
                    value={quantity}
                    className="w-16 p-2 text-center border rounded-lg"
                    readOnly
                  />
                  <button
                    onClick={() => setQuantity(quantity + 1)}
                    className="p-2 border rounded-lg hover:bg-gray-50"
                  >
                    <Plus size={20} />
                  </button>
                </div>

                <button className="w-full py-2 bg-green-600 text-white rounded-lg hover:bg-green-700">
                  เพิ่มในตะกร้า
                </button>
              </div>
            </div>
          </div>

          {/* Product Description */}
          <div className="mt-8">
            <h2 className="text-xl font-medium mb-4">รายละเอียดสินค้า</h2>
            <ul className="list-disc pl-5 space-y-2 text-gray-600">
              <li>แป้งข้าวเหนียวคุณภาพดี</li>
              <li>วัตถุดิบที่ผ่านกระบวนการผลิตที่ได้มาตรฐาน</li>
              <li>ผ่านการผลิตที่ได้รับรองมาตรฐาน GMP, HACCP, HALAL</li>
              <li>เนื้อแป้งมีความละเอียดพิเศษ เหมาะ รูป มันไส้เผือก</li>
            </ul>
          </div>

          {/* Product Banner */}
          <div className="mt-8">
            <img
              src="/api/placeholder/1200/400"
              alt="Product Banner"
              className="w-full rounded-lg"
            />
          </div>

          {/* Related Products */}
          <div className="mt-8">
            <h2 className="text-xl font-medium mb-4">รหัสสินค้าที่เกี่ยวข้อง</h2>
            <p className="text-sm text-gray-500 mb-4">Total 4 Code</p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {relatedProducts.map((product) => (
                <div key={product.id} className="border rounded-lg p-4">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-full object-contain mb-2"
                  />
                  <h3 className="font-medium">{product.name}</h3>
                  <p className="text-sm text-gray-500">{product.code}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailAdmin;