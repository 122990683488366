import React, { useEffect, useState } from "react";
import { Search, ShoppingCart } from "lucide-react";
import { CiHeart } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import Footer from "./Nav/Footer";
import axios from "axios";
const HomePage = () => {
  const [siteSetting, setSiteSetting] = useState({})
  const popularItems = [
    { name: "Coarse Tapioca Flour", price: 1.075, image: "/PRODUCT.png" },
    { name: "Glutinous Rice Flour", price: 1.0, image: "/PRODUCT.png" },
    { name: "Tapioca Starch", price: 1.15, image: "/PRODUCT.png" },
    { name: "Rice Flour", price: 1.0, image: "/PRODUCT.png" },
  ];

  const newArrivals = [
    { name: "Crop Top", price: 8, image: "/NewArrive.png" },
    { name: "Crop Top", price: 8, image: "/NewArrive.png" },
    { name: "Crop Top", price: 8, image: "/NewArrive.png" },
    { name: "Crop Top", price: 8, image: "/NewArrive.png" },
  ];

  const sizes = ["S", "M", "L", "XL"];


  function getSiteDisplaySetting(){
    const token = localStorage.getItem("token")
    axios.get(`${process.env.REACT_APP_API}/api/v1/setting/site-display`,{
      headers : {
        Authorization : `Bearer ${token}`
      }
    }).then((res)=>{
      console.log(res.data)
      const Setting = res.data.data
      // setSiteSetting(Setting)
    }).catch((err)=>{
      console.error(err)
    })
  }

  useEffect(()=>{
    getSiteDisplaySetting()
  },[])

  return (
    <div className="bg-white">
      <main className="container mx-auto mt-8">
        <section className="mb-12">
          <div className="relative h-[738px]">
            <img
              // src={siteSetting.background_image !== null ? siteSetting?.background_image : "/HERO_HOMEPAGE.jpeg"}
              src="/HERO_HOMEPAGE.jpeg"
              alt="Rice field"
              className="w-full h-full object-cover opacity-25 "
            />
            <div className="p-2.5 flex flex-col absolute inset-0 bg-black bg-opacity-50 items-center justify-center">
              {/* <img className="w-18 h-16" src={siteSetting.logo_image !== null ? siteSetting.logo_image : "./LOGO.png"} alt="logo" /> */}
              <img className="w-18 h-16" src="/LOGO.png" alt="logo" />

              <h1 className="text-4xl font-semibold mt-4 text-center">
                { siteSetting.company_name !== null ?siteSetting?.company_name : 'Pornkamon Rice Flour Mills Co.,Ltd.'}
              </h1>
              <div className="w-full h-max flex justify-center items-center mt-12">
                <button className="w-[113px] h-[42px] bg-[#FD7E14] text-white font-medium text-sm">
                  Pre-Orders
                </button>
                <button className="ml-4 w-[113px] h-[42px] bg-white text-[#FD7E14] font-medium text-sm">
                  Quotations
                </button>
              </div>
              <div className="flex justify-center items-center w-full h-[117px] border rounded-full border-black mt-14 md:w-[416px]">
                <h1 className="font-medium text-3xl cursor-pointer">
                  E-Catalogs
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Popular Items</h2>
          <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 ">
            {popularItems.map((item, index) => (
              <div
                key={index}
                className="relative rounded-lg p-4 flex flex-col items-center border"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-full object-contain mb-2 rounded-lg"
                />
                <div className="w-full h-full max-h-36">
                  <h3 className="font-semibold">{item.name}</h3>
                  <p className="font-bold">${item.price.toFixed(4)}</p>
                </div>

                <div className="flex w-full h-full">
                  {sizes.map((size, idx) => (
                    <div
                      key={idx}
                      className="flex justify-center items-center rounded my-2.5 w-9 h-9 border border-[#FD7E14] mr-2.5"
                    >
                      {size}
                    </div>
                  ))}
                </div>

                <div className="flex justify-between w-full mt-2">
                  <button className="bg-orange-500 text-white px-2.5 py-1 rounded font-medium">
                    Add to Cart
                  </button>
                </div>
                <div className="absolute bottom-5 right-2.5 flex justify-center items-center w-10 h-10 rounded-full bg-[#FD7E14]">
                  <CiHeart className="text-white text-2xl cursor-pointer" />
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-12 flex flex-col md:flex-row">
          <div className="container   flex flex-col  items-center  mx-auto      w-full md:w-1/2 pr-4 mb-4 md:mb-0  justify-center md:items-start">
            <h2 className="text-3xl font-bold md:text-4xl">
              ด้วยกรรมวิธีที่ได้มาตรฐานและ
            </h2>
            <h2 className="text-3xl font-bold mb-4 md:text-4xl">
              การคัดสรรวัตถุดิบที่มีคุณภาพ
            </h2>
            <p className="text-gray-600 w-11/12 p-1">
              Personal style in fashion is more than just what you wear. It's a
              visual manifestation of your personality. It's the art of curating
              outfits that resonate with your inner essence.
            </p>
          </div>
          <div className=" flex justify-center items-center w-full md:w-1/2">
            <img
              src="/Innovation.jpeg"
              alt="Rice processing"
              className="w-100 h-[323px] object-cover rounded-lg"
            />
          </div>
        </section>

        <section className="flex  flex-col overflow-hidden">
          <h2 className="text-2xl font-extrabold	 mb-4 text-center">
            Production Process
          </h2>
          <div className="w-full h-full flex flex-col justify-around items-center md:flex-row ">
            <img
              src="/ProductionProcess.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-cover rounded-lg mb-2.5 md:w-[235px]"
            />
            <img
              src="/ProductionProcess.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-cover rounded-lg mb-2.5 md:w-[235px]"
            />
            <img
              src="/ProductionProcess.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-cover rounded-lg mb-2.5 md:w-[235px]"
            />
            <img
              src="/ProductionProcess.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-cover rounded-lg mb-2.5 md:w-[235px]"
            />
            <img
              src="/ProductionProcess.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-cover rounded-lg mb-2.5 md:w-[235px]"
            />
           
          </div>
        </section>

        <section className="mb-12 mt-28">
          <h2 className="text-2xl font-bold mb-4">New Arrivals</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {newArrivals.map((item, index) => (
              <div
                key={index}
                className="relative rounded-lg p-4 flex flex-col items-center border"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-full object-contain mb-2 rounded-lg"
                />
                <div className="w-full h-full max-h-36">
                  <h3 className="font-semibold">{item.name}</h3>
                  <p className="font-bold">${item.price.toFixed(4)}</p>
                </div>

                <div className="flex w-full h-full">
                  {sizes.map((size, idx) => (
                    <div
                      key={idx}
                      className="flex justify-center items-center rounded my-2.5 w-9 h-9 border border-[#FD7E14] mr-2.5"
                    >
                      {size}
                    </div>
                  ))}
                </div>

                <div className="flex justify-between w-full mt-2">
                  <button className="bg-orange-500 text-white px-2.5 py-1 rounded font-medium">
                    Add to Cart
                  </button>
                </div>
                <div className="absolute bottom-5 right-2.5 flex justify-center items-center w-10 h-10 rounded-full bg-[#FD7E14]">
                  <CiHeart className="text-white text-2xl cursor-pointer" />
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-12 flex flex-col md:flex-row mt-4">
          <div className=" flex justify-center items-center w-full md:w-1/2">
            <img
              src="/Factory.jpeg"
              alt="Rice processing"
              className="w-100 h-[323px] object-cover md:rounded-lg"
            />
          </div>
          <div className="w-full  md:w-1/2 pr-4 mb-4 md:mb-0  flex flex-col mt-2.5  justify-center items-start">
            <h2 className="text-3xl font-extrabold mb-2.5 md:text-4xl">
              Pornkamon Offers a Wide
            </h2>
            <h2 className="text-3xl font-extrabold mb-4 md:text-4xl">
              Variety of Rice Flours
            </h2>
            <p className="text-gray-600 w-full p-1">
              Pornkamon Offers a Wide Variety of Rice Flours is Certified for :
              GMP, HACCP, HALAL, KOSHER, US FDA, Organic EU, NOP, COR
            </p>
          </div>
        </section>

        <section className="flex  flex-col overflow-hidden mt-4">
          <h2 className="text-2xl font-extrabold	 mb-4 text-center">
            Quality Certificates
          </h2>
          <div className="w-full h-full  mb-8 flex justify-center items-center">
            <div className="w-[136px] h-[31px] flex justify-center items-center bg-[#FD7E14] rounded">
              <FaInstagram className="text-white text-xl" />

              <h1 className="ml-2.5 font-bold text-white">Download</h1>
            </div>
          </div>
          <div className="w-full h-full flex flex-col justify-around items-center md:flex-row ">
            <img
              src="/Cert.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-contain mt-2.5 rounded-lg mb-2.5 md:w-[235px]"
            />
            <img
              src="/Cert.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-contain mt-2.5 rounded-lg mb-2.5 md:w-[235px]"
            />{" "}
            <img
              src="/Cert.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-contain mt-2.5 rounded-lg mb-2.5 md:w-[235px]"
            />{" "}
            <img
              src="/Cert.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-contain mt-2.5 rounded-lg mb-2.5 md:w-[235px]"
            />{" "}
            <img
              src="/Cert.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-contain mt-2.5 rounded-lg mb-2.5 md:w-[235px]"
            />{" "}
            <img
              src="/Cert.jpeg"
              alt="Production process"
              className="w-11/12 h-[235px] object-contain mt-2.5 rounded-lg mb-2.5 md:w-[235px]"
            />
          </div>
        </section>
      </main>

          <div className="h-[100px] w-full"></div>
            <Footer/>
    </div>
  );
};

export default HomePage;
