import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./components/HomePage";
import NavigationBar from "./components/Nav/NavigationBar";
import NavFunctionBar from "./components/Nav/NavFunctionBar";
import Footer from "./components/Nav/Footer";
import Products from "./components/Products";
import CartPage from "./components/Cart/CartPage";
import { Login } from "./components/Auth/Login";
import RegistrationPage from "./components/Auth/Register";
import CheckoutPage from "./components/Cart/Checkout";
import VideoApp from "./components/Cart/test";
import AdminPage from "./components/Admin/Main";
import NewPurchaseOrder from "./components/Admin/NewPurchaseOrder";
import ProductDetail from "./components/Admin/ProductDetail";
import OverviewWithBarcodeAdmin from "./components/Admin/OverviewWithBarcode";
import ProductGrid from "./components/Admin/ProductGrid";

function App() {
  const location = useLocation(); // Get the current location

  // Check if the path starts with '/admin'
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <div className="w-full h-screen">
      {!isAdminRoute && (
        <>
          <NavigationBar />
          <NavFunctionBar />
        </>
      )}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/products" element={<Products />} />
        <Route path="/sale" element={<Products />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/video" element={<VideoApp />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/product/grid" element={<ProductGrid />} />
        <Route path="/admin/create/po" element={<NewPurchaseOrder />} />
        <Route path="/admin/create/po/product/detail" element={<ProductDetail />} />
        <Route path="/admin/overview/barcode" element={<OverviewWithBarcodeAdmin />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
